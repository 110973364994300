import getData from "@/__main__/get-data.mjs";
import { getWeapons } from "@/game-val/api.mjs";
import { networkOptions } from "@/game-val/fetch-constants-data.mjs";
import { WeaponsModel } from "@/game-val/models/weapons.mjs";

export default async function fetchData() {
  await getData(
    getWeapons(),
    WeaponsModel,
    ["val", "cms", "weapons"],
    networkOptions,
  );
}
